//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'GetAppHeaderBanner',
  data: function data() {
    return {
      show: true
    };
  },
  computed: {
    profileHasAutoPassword: function profileHasAutoPassword() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['player/profile']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.hasAutoPassword;
    },
    nativeApp: function nativeApp() {
      return this.$store.$app.nativeApp;
    },
    getLink: function getLink() {
      return this.nativeApp.getLink();
    }
  },
  methods: {
    hideGetAppHeaderBanner: function hideGetAppHeaderBanner() {
      this.show = false;
      this.$store.$cookies.set('getAppHeaderBannerShowed', '1', {
        expires: '365d',
        path: '/'
      });
    },
    onSubmit: function onSubmit() {
      if (!this.profileHasAutoPassword) {
        window.location.href = this.getLink;
      } else {
        this.$store.dispatch('modals/setGetAppModal', {
          isOpen: true
        });
      }
    }
  }
};